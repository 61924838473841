//import {test_function} from "./../functions/test_function.js";

/*

import {getId} from "./../universal_methods/getId.js";  
import {setId} from "./../universal_methods/setId.js";
import {isEmpty} from "./../universal_methods/isEmpty.js";  
import {getAttributes} from "./../universal_methods/getAttributes.js";  



*/

//import {is} from "is_js";

import { generateId } from "../universal_methods/generateId.js";

// import {Peer} from "peerjs"; 


import { globalVar } from
    "./../../variables/global_variables.js";

import { getRandomStringsAndNumbers } from
    "./../../functions/random/randomStrings.js";

import { getRandomStringsWithoutNumbers } from
    "./../../functions/random/randomStrings.js";

import { changeFirstLetterToLowerCase } from
    "./../../functions/change/changeFirstLetterToLowerCase.js";

import { isEmpty } from "./../universal_methods/isEmpty.js";

import { debounce } from "./../universal_methods/debounce";

import { SmetioAjax } from
    "./../smetioAjax/SmetioAjax.js";

import { SmetioNotification } from "../smetioNotification/v1.0.0/SmetioNotification.js";


const is = require("is_js");
const DetectRTC = require("detectrtc");
const hash = require("object-hash");



import { events } from "./methods/events.js";

// //import "./styles/_main.scss";


import { render } from "./methods/render.js";
import { getCanvasFingerprint } from "./methods/getCanvasFingerprint.js";
import { getScreenResolution } from "./methods/getScreenResolution.js";
import { getFormFactor } from "./methods/getFormFactor.js";
import { getBrowserBrand } from "./methods/getBrowserBrand.js";
import { getBrowserPlatform } from "./methods/getBrowserPlatform.js";
import { getVisitorsToken } from "./methods/getVisitorsToken.js";
import { setVisitorToken } from "./methods/setVisitorToken.js";
import { hasSessionStorage } from "./methods/hasSessionStorage.js";
import { hasLocalStorage } from "./methods/hasLocalStorage.js";
import { hasIndexDb } from "./methods/hasIndexDb.js";
import { supportsCanvas } from "./methods/supportsCanvas.js";
import { supportsGeolocation } from "./methods/supportsGeolocation.js";
import { supportsNotification } from "./methods/supportsNotification.js";
import { checkBrowserPermissions } from "./methods/checkBrowserPermissions.js";

import { trackGeolocation } from "./methods/trackGeolocation.js";
import { stopTrackingGeolocation } from "./methods/stopTrackingGeolocation.js";
import { getIpAddressFromWebsocket } from "./methods/getIpAddressFromWebsocket.js";
import { getPeerJsId } from "./methods/getPeerJsId.js";
import { isLegalPoliciesAccepted } from "./methods/isLegalPoliciesAccepted.js";
import { showLegalPolicyNotice } from "./methods/showLegalPolicyNotice.js";
import { acceptLegalPolicies } from "./methods/acceptLegalPolicies.js";
import { register } from "./methods/register.js";
import { showOfflineNotification } from "./methods/showOfflineNotification.js";
import { pingServer } from "./methods/pingServer.js";
import { documentVisibilityHasChanged } from "./methods/documentVisibilityHasChanged.js";
import { windowIsAboutToUnload } from "./methods/windowIsAboutToUnload.js";

import { registerClickedLinks } from "./methods/registerClickedLinks.js";
import { registerMultimediaInteractions } from "./methods/registerMultimediaInteractions.js";
import { registerMouseAndScrollPosition } from "./methods/registerMouseAndScrollPosition.js";
import { updateMousePosition } from "./methods/updateMousePosition.js";



import { showNativeNotification } from "./methods/showNativeNotification.js";
import { supportsServiceWorker } from "./methods/supportsServiceWorker.js";
import { supportsPushManager } from "./methods/supportsPushManager.js";
import { registerServiceWorker } from "./methods/registerServiceWorker.js";
import { showNotificationWithServiceWorker } from "./methods/showNotificationWithServiceWorker.js";
import { SMETIO_GEOLOCATION_TIMEOUT, SMETIO_GEOLOCATION_UNKNOWN_ERROR, SMETIO_PERMISSION_DENIED, SMETIO_POSITION_UNAVAILABLE } from "../../constants/smetio_geolocation_constants.js";



export class SmetioVisitor {

    constructor(data = {
        language: "en"
    }) {

        //this.peer = Peer;

        this.is = is;
        this.detectRTC = DetectRTC;
        this.hash = hash;
        this.gv = globalVar;
        this.logo = data.logo;

        this.SmetioAjax = new SmetioAjax();
        this.isEmpty = isEmpty;
        this.debounce = debounce;
        this.generateId = generateId;

        this.getRandomStringsAndNumbers = getRandomStringsAndNumbers;
        this.getRandomStringsWithoutNumbers = getRandomStringsWithoutNumbers;
        this.changeFirstLetterToLowerCase = changeFirstLetterToLowerCase;

        this.events = events;
        this.render = render;
        this.getCanvasFingerprint = getCanvasFingerprint;
        this.getScreenResolution = getScreenResolution;
        this.getFormFactor = getFormFactor;
        this.getBrowserBrand = getBrowserBrand;
        this.getBrowserPlatform = getBrowserPlatform;
        this.getVisitorsToken = getVisitorsToken;
        this.setVisitorToken = setVisitorToken;
        this.hasSessionStorage = hasSessionStorage;
        this.hasLocalStorage = hasLocalStorage;
        this.hasIndexDb = hasIndexDb;
        this.supportsCanvas = supportsCanvas;
        this.supportsGeolocation = supportsGeolocation;
        this.supportsNotification = supportsNotification;
        this.checkBrowserPermissions = checkBrowserPermissions;

        this.trackGeolocation = trackGeolocation;
        this.stopTrackingGeolocation = stopTrackingGeolocation;
        this.getIpAddressFromWebsocket = getIpAddressFromWebsocket;
        this.getPeerJsId = getPeerJsId;
        this.isLegalPoliciesAccepted = isLegalPoliciesAccepted;
        this.showLegalPolicyNotice = showLegalPolicyNotice;
        this.acceptLegalPolicies = acceptLegalPolicies;
        this.register = register;
        this.showOfflineNotification = showOfflineNotification;
        this.pingServer = pingServer;
        this.documentVisibilityHasChanged = documentVisibilityHasChanged;
        this.windowIsAboutToUnload = windowIsAboutToUnload;

        this.registerClickedLinks = registerClickedLinks;
        this.registerMultimediaInteractions = registerMultimediaInteractions;
        this.registerMouseAndScrollPosition = registerMouseAndScrollPosition;
        this.updateMousePosition = updateMousePosition;

        this.SmetioNotification = SmetioNotification;
        this.smetioNotification = "";

        this.showNativeNotification = showNativeNotification;
        this.supportsServiceWorker = supportsServiceWorker;
        this.supportsPushManager = supportsPushManager;
        this.registerServiceWorker = registerServiceWorker;
        this.showNotificationWithServiceWorker = showNotificationWithServiceWorker;





        this.smetioLegalPolicyCurrentVersion = $("body").attr("data-smetio-legalPolicyCurrentVersion") || 0;

        this.server = this.gv.smetio_retrieveData_url + "registerVisitors/";
        this.serverPingLocation = this.gv.smetio_retrieveData_url + "registerPingFromVisitors/";
        this.serverClickLinkLocation = this.gv.smetio_retrieveData_url + "registerClickedLinks/";
        this.serverMultimediaInteractLocation = this.gv.smetio_retrieveData_url + "registerMultimediaInteractions/";
        this.serverMouseAndScrollPositionLocation = this.gv.smetio_retrieveData_url + "registerMouseAndScrollPosition/";
        this.logo = this.gv.smetio_cdn + "img/smetio_brands/smetio/smetio_logo_icon.svg";
        // this.logo = this.logo;
        // http://localhost:3000/img/3ack/svg/3ack_icon_color.svg
        //this.mainServiceWorkerLocation = this.gv.smetio_cdn+"js/serviceWorkers/main_serviceWorker.js";
        this.mainServiceWorkerLocation = "/serviceWorkers/main_serviceWorker.js";

        this.visitorToken = "";
        this.isRegistered = false;
        this.isLoggedIn = false;
        this.hiddenElementForOnlineStatus = false;
        this.hiddenElementForOnlineStatusClass = this.generateId(7);

        this.onlineStatusChecker = false;
        this.offlineNotice = false;
        this.mainServiceWorker = false;
        //240000 IS 4 MINUTES IN MILLISECONDS
        //this.pingInterval = 240000; 

        //60000 IS 1 MINUTES IN MILLISECONDS
        this.pingInterval = 60000;
        this.currentTabStatus = "active";
        this.pageIsClosed = false;
        this.clickedLinkHref = "";

        this.mouseX = "";
        this.mouseY = "";
        this.scrollY = "";
        this.scrollX = "";
        // WILL BE OBTAINED USING FROM BACKEND

        this.backend_ip = {

            ip: "",
            address: "",
            city: "",
            postcode: "",
            country: ""

        };

        /**** 
           this.textsInView OBJECT REFERS TO THE TEXTS THAT USER CAN SEE AND/OR ENGAGE WITH AT THE CURRENT STATE 
           OF THE APPLICATION. THESE TEXTS COULD BE html value, title, alt, placeholder etc         

           THIS IS USED MAINLY FOR SINGLE PAGE APPLICATIONS AND CAN BE ACCESSED VIA 

           window.smetioVisitor.textsInView

         * ****/

        this.textsInView = {};

        /****
         *  WILL BE OBTAINED USING THE this.getGeolocation() FROM render.js FILE OR WHEN EVER NEEDED 
        ****/

        this.geoLocationOptions = {

            enableHighAccuracy: true,
            timeout: 20000,
            maximumAge: 0

        };

        this.geoLocationTrackingId = false;

        this.geoLocation = {

            latitude: "",
            longitude: "",
            accuracy: "",
            altitude: "",
            altitudeAccuracy: "",
            heading: "",
            speed: "",
            address: "",
            state: "",
            district: "",
            country: "",
            currentError: ""

        };

        /**** 
         * THE PERMISSION GIVEN BY THE BROWSER TO THE CURRENT DOMAIN 
         * IS POPULATED WHEN THE this.checkBrowserPermissions IS CALLED IN THE render.js FILE
         * 
         * ****/
        this.browserPermissions = {

        };

        /**** DETAILS OF THE CURRENT PAGE/WEBSITE ****/

        this.visitedWebsiteParameters = new URLSearchParams(window.location.search);

        this.language = data.language || this.visitedWebsiteParameters.get("language") || "en";

        this.visitedWebsite = {

            domain: window.location.hostname,
            url: window.location.href,
            referrer: document.referrer,
            language: this.language,
            //browserPermissions: this.browserPermissions,

            /**** 
             * EACH VISITED PAGE WILL HAVE ITS OWN PEERID AND THIS WILL BE STORED IN THE DATABASE
             * WHERE IT CAN BE RETRIEVED AND USED TO COMMUNICATE WITH THE VISITOR OF THE PAGE          
             * ****/
            peerJsId: ""

        };

        this.browser = {

            brand: "",
            platform: "",
            platformVersion: this.detectRTC.osVersion,
            version: this.detectRTC.browser.version,
            userAgent: navigator.userAgent,
            doNotTrack: navigator.doNotTrack || false,
            hasSessionStorage: true,
            hasLocalStorage: true,
            hasIndexDb: true,
            supportsCanvas: true,
            supportsWebRtc: this.detectRTC.isWebRTCSupported,
            supportsWebsocket: this.detectRTC.isWebSocketsSupported,
            supportsScreenSharing: this.detectRTC.isScreenCapturingSupported,
            supportsGeolocation: true,
            supportsNotification: true,
            supportsPushManager: true,
            supportsServiceWorker: true,
            legalPoliciesAccepted: false,
            legalPoliciesAcceptedDate: false,
            versionOfLegalPoliciesAccepted: 0

        };

        this.device = {

            canvasFingerprint: "",
            formFactor: "",
            language: navigator.language.substring(0, 2),
            screenResolution: "",
            screenColorDept: screen.colorDepth,
            pixelDept: screen.pixelDepth,
            cpuClass: navigator.cpuClass || "Unknown",
            platform: navigator.platform,

        };

        /**** 
         * this.peerJsObject
         * WILL BE USED TO CREATE AN INSTANCE OF peerJs AND WILL BE USED TO COMMUNICATE WITH 
         * THE VISITOR VIA WEBRTC
         * 
         *  ****/
        this.peerJsObject = "";

        this.data = {

            /**** 
             * VISITOR TOKEN SHOULD BE SET IN THE BACKEND AT THE VERY FIRST TIME
             * 
             *  ****/
            visitorToken: this.visitorToken,

            currentDate: new Date().toISOString().slice(0, 10),
            timeZone: new Date().getTimezoneOffset(),
            isUsingVpn: false,
            isOnline: true,
            isUsingIncognito: this.detectRTC.browser.isPrivateBrowsing,


            ipAddress: this.backend_ip,
            geoLocation: this.geoLocation,
            visitedWebsite: this.visitedWebsite,
            browser: this.browser,
            device: this.device

        };


        //this.setId();
        //this.events = events;

    };

    createHiddenInputForOnlineStatusUpdate() {

        /**** 
         * THE HIDDEN ELEMENT IS CREATED AND APPENDED TO THE BODY 
         * THIS IS DONE SO THAT WHENEVER THE ONLINE STATUS OF THE USER CHANGES, 
         * THE VALUE OF THIS ELEMENT WILL BE CHANGED 
         * AND USING AN EVENT LISTENER ON THE APP USER INTERFACE CLASS, 
         * THIS CHANGE CAN BE PICKED UP AND REFLECTED ON THE USER INTERFACE
         * 
         * ****/
        if (this.hiddenElementForOnlineStatus) return true;

        this.hiddenElementForOnlineStatus = `
            <input type="hidden" class="${this.hiddenElementForOnlineStatusClass}">
        `;

        $("body").append(this.hiddenElementForOnlineStatus);

        return true;

    };

    handleUserLoginStatus(result) {

        if (!result.userLogInStatus) return false;

        this.isLoggedIn = result.userLogInStatus.isLoggedIn;

        if (this.createHiddenInputForOnlineStatusUpdate()) {

            $("." + this.hiddenElementForOnlineStatusClass).val(result.userLogInStatus.onlineStatus).trigger("change");

        };

        if (!this.isEmpty(result.userLogInStatus.redirectLink)) location.href = result.userLogInStatus.redirectLink;

    };

    handGeolocationError(data = {
        error: null, // COMPULSORY
        callback: null // OPTIONAL MUST BE A FUNCTION
    }) {

        //console.log(error);
        const error = data.error;

        for (const key in this.geoLocation) {

            this.geoLocation[key] = null;

        };

        switch (error.code) {

        case error.PERMISSION_DENIED:
            //x.innerHTML = "User denied the request for Geolocation."
            this.geoLocation.currentError = SMETIO_PERMISSION_DENIED;
            break;

        case error.POSITION_UNAVAILABLE:
            //x.innerHTML = "Location information is unavailable."
            this.geoLocation.currentError = SMETIO_POSITION_UNAVAILABLE;
            break;

        case error.TIMEOUT:
            //x.innerHTML = "The request to get user location timed out."
            this.geoLocation.currentError = SMETIO_GEOLOCATION_TIMEOUT;
            break;

        case error.UNKNOWN_ERROR:
            //x.innerHTML = "An unknown error occurred."
            this.geoLocation.currentError = SMETIO_GEOLOCATION_UNKNOWN_ERROR;

            break;

        };

        // CHECK this.geoLocation.currentError TO SEE THE ERROR 

        if (data.callback) data.callback(this.geoLocation.currentError);

        return false;

    };

    updateGeolocation(data = {
        callback: null, // OPTIONAL
        position: null //COMPULSORY
    }) {

        const position = data.position;

        this.geoLocation.latitude = position.coords.latitude;

        this.geoLocation.longitude = position.coords.longitude;

        this.geoLocation.accuracy = position.coords.accuracy;

        this.geoLocation.altitude = position.coords.altitude;

        this.geoLocation.altitudeAccuracy = position.coords.altitudeAccuracy;

        this.geoLocation.heading = position.coords.heading;

        this.geoLocation.speed = position.coords.speed;

        this.geoLocation.currentError = "";

        if (data.callback) data.callback(this.geoLocation);

        return true;

    };

    async getGeolocation(data = {
        priority: null, // COMPULSORY CAN  BE "important" or "not important"
        callback: null // COMPULSORY MUST BE A FUNCTION
    }) {

        this.geoLocation.currentError = "";

        if (this.browserPermissions.geolocation !== "granted" && data.priority !== "important") return false;

        return navigator.geolocation.getCurrentPosition(async (position) => {

            return this.updateGeolocation({
                position,
                callback: data.callback
            });

        }, async (error) => {

            return this.handGeolocationError({
                error,
                callback: data.callback
            });
            //return this.handGeolocationError(error);

        }, this.geoLocationOptions);

    };

    showNotification(data = {
        type: null, // OPTIONAL - "friendly" IS DEFAULT BUT CAN ALSO BE "success" OR "danger" 
        icon: null, // OPTIONAL - TAKEN FROM SMETIO ICONS 
        duration: null, // OPTIONAL - HOW THE NOTIFICATION SHOULD BE VISIBLE BEFORE DISAPPEARING
        isTimed: null, // OPTIONAL - WEATHER THE NOTIFICATION SHOULD DISAPPEAR AFTER THE SET TIME OR WAIT TILL THE USER CLOSES IT
        title: null, // THE TITLE OF THE NOTIFICATION 
        body: null, // COMPULSORY - IS AN ARRAY OF OBJECT AND EACH OBJECT MUST CONTAIN FOR EXAMPLE {message: "txt100", errorCode: 1} - SEE THE getBody FUNCTION FOR DETAILS   {
        id: null // OPTIONAL - USED TO INTEREACT WITH THE NOTIFICATION ELEMENT	
    }) {

        this.smetioNotification = new this.SmetioNotification(data);

        if (!this.browser.supportsNotification) {

            return this.smetioNotification.render();

        };

        if (this.browserPermissions["notifications"] == "denied" || this.currentTabStatus == "active") {

            return this.smetioNotification.render();

        }

        if (this.browserPermissions["notifications"] == "granted") {

            return this.showNativeNotification(data);

        }

        Notification.requestPermission().then((permission) => {

            this.browserPermissions["notifications"] = permission;

            if (permission === "granted") {

                return this.showNativeNotification(data);
            };

            return this.smetioNotification.render();

        });



    };

    async getSmetioTexts() {

        const myAxios = await import("axios" /* webpackPrefetch: true */).then((axios) => { return axios.default; });

        // const url = window.smetioEnv == "development" ? `http://localhost:3000/texts/smetioTexts/${this.language}.json` : `https://cdn.3ack.com/production/texts/smetioText/${this.language}.json`;
        // const url = window.smetioEnv == "development" ? `http://localhost:4000/api/texts/${this.language}` : `http://localhost:4000/api/texts/${this.language}`;
        const url = `${window.apiEndPoint}/texts/${this.language}`;

        /* 
        ALL SET IN THE INITIALIZATION CLASS
            window.defaultPath = this.defaultPath;
            window.apiEndPoint = this.apiEndPoint;
            window.userLanguage = this.language;
            window.themeName = this.themeName;
            window.appName = this.appName;
        */

        try {

            const response = await myAxios.get(url);
            console.log("The smetio text is gotten ****");
            window["smetioTexts"] = response.data;


        } catch (error) {
            console.error(error);
        };
    }

};

// const smetioVisitor = new SmetioVisitor();
// smetioVisitor.render();
// window.smetioVisitor = new SmetioVisitor();
// window.smetioVisitor.render();




